<template functional>
  <div class="price">
    <div class="old-price" v-if="props.item.unitPriceStandard">
      {{ parent.$n(props.item.unitPriceStandard, "currency")
      }}<span
        class="weight-unit"
        v-if="props.item.product.productInfos.TIPOLOGIA != 'Pezzo'"
        >/{{
          props.showWeightUnitBase
            ? props.item.product.productInfos.WEIGHT_UNIT_BASE
            : props.item.product.weightUnitDisplay
        }}</span
      >
    </div>
    <div
      class="cur-price"
      :class="{ 'promo-price': props.item.unitPriceStandard }"
    >
      {{ parent.$n(props.item.unitPrice, "currency") }}
      <span
        class="weight-unit"
        v-if="props.item.product.productInfos.TIPOLOGIA != 'Pezzo'"
        >/{{
          props.showWeightUnitBase
            ? props.item.product.productInfos.WEIGHT_UNIT_BASE
            : props.item.product.weightUnitDisplay
        }}</span
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
.price {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: $text-color;
  white-space: nowrap;
  .weight-unit {
    font-size: 12px;
    margin-left: -5px;
  }
  .old-price {
    font-size: 14px;
    line-height: 1;
    text-decoration: line-through;
  }
}
</style>
<script>
export default {
  name: "props.orderItemPrice",
  props: {
    item: { type: Object, required: true },
    showWeightUnitBase: { type: Boolean, required: false, default: false }
  }
};
</script>
